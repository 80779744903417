<template>
  <div class="container-fluid">
    <div class="row" v-if="type != '' && hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
            <button class="btn btn-lg btn-primary" @click="hamla = '1'; getcamps()">
                البشائر
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
            <button class="btn btn-lg btn-success" @click="hamla = '2'; getcamps()">
                الميسر
            </button>
        </div>
    </div>
    <div class="row" v-if="type == '' && hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="type_1">
            <button class="btn btn-lg btn-primary" @click="type = '1';">
                منى / مكة
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="type_2">
            <button class="btn btn-lg btn-success" @click="type = '2';">
                عرفات
            </button>
        </div>
    </div>
    <div class="row" v-if="type != '' && hamla != ''">
        <div class="col-12 col-lg-6 c">
            <div class="card">
                <div class="card-header">
                    <div class="text-center col-12" id="result">
                        قم بالفحص الآن...
                    </div>
                </div>
                <div class="card-body col-12 col-lg-6 c">
                    <div class="form-group">
                      <input type="text"
                        class="form-control form-control-lg text-center" style="text-align: center" id="barcode" v-model="barcode">
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            user: JSON.parse(localStorage.getItem("user")),
            camps: [],
            hamla: "",
            type: "",
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1"),
            type_1: checkPer('camps_1'),
            type_2: checkPer('camps_2'),
            barcode: "",
            interval: null
        }
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval)
        }
    },
    created(){
        if(!checkPer('scan')){
            this.$router.push('/pers')
            return;
        }
        setInterval(() => {
            if($("#barcode").length){
                if(!$("#barcode").is(":focus")){
                    $("#barcode").focus()
                }
            }   
        });
        var g = this;
        g.interval = setInterval(() => {
            if(g.barcode != ""){
                g.checkBarcode(g.barcode)
                g.barcode = "";
            }
        }, 100);
    },
    methods: {
        getcamps(){
            var g = this;
            $.post(api + '/admin/camps/list-with-users', {
                jwt: g.user.jwt,
                type: g.type,
                hamla: g.hamla
            }).then(function(r){
                g.camps = r.response;
            })
        },
        checkBarcode(number){
            var g = this;
            var user = {}, camp = {};
            g.camps.forEach(function(a){
                a.users.forEach(function(b){
                    if(b.number == number){
                        user = b;
                        camp = a;
                    }
                })
            })
            if(!user?._id){
                $("#result").html(`
                <div class='alert alert-danger g'>
                    رقم الهوية '${number}' غير موجود في الخيم المحددة.
                </div>
                `);
            }else{
                $("#result").html(`
                <div class='alert alert-info g'>
                    جاري التحضير...
                </div>
                `);
                var g = this;
                $.post(api + '/admin/camps/attend', {
                    jwt: g.user.jwt,
                    number: number,
                    camp_id: camp._id
                }).then(function(r){
                    if(r.status == 100){
                        $("#result").html(`
                        <div class='alert alert-success g'>
                            <h5>تم تحضير الحاج '${user.name}' في الخيمة '${camp.title}' بنجاح.</h5>
                        </div>
                        `);
                    }else{
                        $("#result").html(`
                        <div class='alert alert-warning g'>
                            <h5>${r.response}</h5>
                        </div>
                        `);
                    }
                })
            }
        }
    }
}
</script>
